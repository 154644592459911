$ = jQuery;

function setupCategorySection() {
  $('.nav-post-content').click(function(ev){
    ev.preventDefault();
    var targetName = $(ev.target).data('target');
    var size = $(ev.target).data('size');
    $('#'+targetName+' .nav-link').removeClass('active');
    $(ev.target).addClass('active');

    $.ajax({
      url: ajax_handler.url,
      type: 'POST',
      data: {
        action: 'jc_ajax_get_home_category_posts_cards',
        category: $(ev.target).data('category'),
        size: size
      },
      success: function (response) {
        if($('#'+targetName+' .card-list .jc-ad-container').length>0){
          $('#'+targetName+' .card-list .post-card').remove();
          $(response.data).insertBefore('#'+targetName+' .card-list .jc-ad-container');
        }else{
          $('#'+targetName+' .card-list').html(response.data);
        }
      },
      error: function (data) {
      }
    });
  });
}
